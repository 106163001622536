import React from 'react';
import { SidebarLogoContainer } from './Sidebar.styled';
import { ReactComponent as Logo } from 'assets/mmi_logo.svg';
import { ReactComponent as FullLogo } from 'assets/mmi_text_logo.svg';
import { CONFIG } from 'shared/config/config';

type Props = {
  collapsed: boolean;
};

const SidebarLogo: React.FC<Props> = ({ collapsed }): JSX.Element => {
  return (
    <SidebarLogoContainer className={`relative ${collapsed ? 'flex justify-center' : ''}`}>
      {collapsed ? <Logo className="without-text" /> : <FullLogo />}
      <small className={`absolute bottom-0 right-5 text-[11px] text-gray-400 ${collapsed ? 'hidden' : ''}`}>
        {CONFIG.APP_VERSION}
      </small>
    </SidebarLogoContainer>
  );
};

export default SidebarLogo;
