/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, DatePicker, Dropdown, Menu, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, isAfter } from 'date-fns';
import { IClientSubscription } from 'shared/interfaces/IClientSubscription';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface Props {
  deleteBuckets: (id: string, bucket_name: string) => void;
  handleOnCopy: (platform: string, id: string) => void;
  update_bucket: (id: string, keyword?: string, keyword_id?: string) => void;
  subscriptionData?: IClientSubscription;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BUCKETS_LIST_COLUMNS = ({
  deleteBuckets,
  handleOnCopy,
  update_bucket,
  subscriptionData,
  setLoading,
}: Props): ColumnsType<any> => [
  {
    dataIndex: 'bucket_name',
    title: 'Bucket Name',
    // sorter: (a, b) => a._id - b._id,
  },
  {
    dataIndex: 'keywords',
    title: 'No. of Keywords',
    // sorter: (a, b) => a._id - b._id,
    render: (_, { buckets_count }) => buckets_count || 0,
  },
  {
    dataIndex: 'date_created',
    title: 'Date Created',
    // sorter: (a, b) => a._id - b._id,
    render: (_, { date_created }) => format(new Date(date_created), 'LLLL dd, yyyy EEEE h:mm a'),
  },
  {
    title: 'Effectivity Date',
    render: ({ effectivity_start, effectivity_end, effectivity_status }) => {
      if (!effectivity_start || !effectivity_end) return '-';
      return (
        <RangePicker
          disabled
          value={[moment(effectivity_start), moment(effectivity_end)]}
          style={{
            backgroundColor: effectivity_status ? '#f6ffed' : '#fff1f0',
            borderColor: effectivity_status ? '#b7eb8f' : '#ffa39e',
          }}
        />
      );
    },
  },
  {
    dataIndex: 'actions',
    title: 'Actions',
    width: 310,
    render: (_, { _id, bucket_name }) => {
      const menu = (
        <Menu
          items={[
            {
              key: '2-3',
              label: 'MediaWatch',
              onClick: () => {
                handleOnCopy('MediaWatch', _id);
                setLoading(true);
              },
              disabled:
                subscriptionData?.plan_details_mediawatch?.status === 'active' &&
                isAfter(new Date(), new Date(subscriptionData?.plan_details_mediawatch?.pkg_date_end || null)),
            },
            {
              key: '2-2',
              label: 'SharedView',
              onClick: () => {
                handleOnCopy('SharedView', _id);
                setLoading(true);
              },
              disabled:
                subscriptionData?.plan_details_sharedview?.status === 'active' &&
                isAfter(new Date(), new Date(subscriptionData?.plan_details_sharedview?.pkg_date_end || null)),
            },

            {
              key: '2-4',
              label: 'AdWatch',
              onClick: () => {
                handleOnCopy('AdWatch', _id);
                setLoading(true);
              },
              disabled:
                subscriptionData?.plan_details_adwatch?.status === 'active' &&
                isAfter(new Date(), new Date(subscriptionData?.plan_details_adwatch?.pkg_date_end || null)),
            },
          ]}
        />
      );
      return (
        <Space>
          <Button size="large" onClick={() => update_bucket(_id, 'keyword')}>
            Add Keyword
          </Button>
          <Button size="large" onClick={() => update_bucket(_id)}>
            Edit
          </Button>
          <Button size="large" onClick={() => deleteBuckets(_id, bucket_name)}>
            Delete
          </Button>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button size="large" type="primary">
              Copy
            </Button>
          </Dropdown>
        </Space>
      );
    },
  },
];

export const KEYWORDS_LIST_COLUMNS = (handleDeleteKeyword, update_bucket): ColumnsType<any> => [
  {
    dataIndex: 'keywords_label',
    title: 'Keyword Label',
  },
  {
    // dataIndex: 'keywords',
    title: 'Keyword Strings',
    render: (_, { keywords }) => {
      return keywords
        ? keywords
            ?.trim()
            ?.replace(/\)\s*\(\s*/g, ') AND (')
            // .replace(') (', ') AND (')
            // .replace(') (', ') AND (')
            // .replace(') (', ') AND (')
            .replace('-(', 'AND NOT (')
            .replace(/[|]/g, ' OR ')
        : '';
    },
  },
  {
    dataIndex: 'actions',
    title: 'Actions',
    width: 310,
    render: (_, { _id, bucket_id }) => {
      return (
        <Space>
          <Button
            size="large"
            onClick={() => {
              update_bucket(bucket_id, 'keyword', _id);
            }}
          >
            Edit
          </Button>
          <Button
            size="large"
            onClick={() => {
              handleDeleteKeyword(_id, bucket_id);
            }}
          >
            Delete
          </Button>
        </Space>
      );
    },
  },
];
