// import React, { useContext, useEffect, useMemo, useState } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { useBucketService } from 'shared/services/bucketService';
import { useCategoryService } from 'shared/services/categoryService';
import { useKeywordService } from 'shared/services/keywordService';
// import { SavingFocusKeywords } from '../Add/Container';
import { useFormik } from 'formik';
import EditView from './EditView';
import { Alert } from 'shared/theme/elements';
import { ROUTES } from 'shared/constants/ROUTES';
import { BreadcrumbContext } from 'shared/context/Breadcrumb';
import { ls } from 'shared/utils/ls';
import useLoading from 'shared/hooks/useLoading';
import { SpinnerWrapper } from 'components/SpinnerWrapper';
import { useClientSubscriptionService } from 'shared/services/clientSubscriptionService';

function Container() {
  const { page, pageSize } = usePagination();

  const { push, location } = useHistory();
  const locationState = location.state as any;
  const queryClient = useQueryClient();
  const user = localStorage?.getItem('user_admin') ? JSON.parse(localStorage.getItem('user_admin') || '') : null;

  // CONTEXT
  const { setAdditionalValue } = useContext(BreadcrumbContext);

  // const [proceed, setProceed] = useState<boolean>(false);
  // const [bucketName, setBucketName] = useState<string>('');
  // const [updateKeywords, setUpdateKeywords] = useState<any>();
  // const [proceedSavingFocusKeywords, setProceedSavingKeywords] = useState<SavingFocusKeywords>({
  //   bucket_name: '',
  //   proceed: false,
  //   focusKeywords: [],
  //   save_alert: '',
  // });

  // CUSTOM LOADING HOOK
  const { loading, setLoading } = useLoading();

  const BUCKET_ID = useMemo(() => {
    if (locationState?.bucket_id) return locationState.bucket_id;

    return undefined;
  }, [locationState]);

  const { updateCategory, getCategories } = useCategoryService();
  // const { getCategories } = useCategoryService();
  const { getBuckets, updateBucket } = useBucketService();
  const { getKeywordShow } = useKeywordService();

  const { updateCategoryMutation } = updateCategory();
  const { mutate: mutateUpdateBucket } = updateBucket();

  const { data: get_category } = getCategories({
    client_obj_id: locationState?.client_id,
    _id: locationState?._id,
    platform: locationState?.platform,
  });

  const get_category_data = get_category?.data || [];

  const BUCKET_IDS = get_category_data[0]?.buckets.map((bucket: any) => bucket._id);

  const { getClientSubscription } = useClientSubscriptionService();

  const { data: subscription } = getClientSubscription({
    client_obj_id: locationState?.client_id,
  });

  const subscriptionData = useMemo(() => subscription?.data[0], [subscription?.data]);

  const { data: bucketsData } = getBuckets({
    buckets_id: BUCKET_IDS,
    limit: 100,
  });

  // const get_buckets = bucketsData?.data || [];
  const get_buckets = useMemo(() => {
    if (!bucketsData?.data) {
      return [];
    }

    return bucketsData.data.map((bucket) => {
      let effectivity_start;
      let effectivity_end;

      if (bucket.effectivity_start && bucket.effectivity_end) {
        effectivity_start = bucket.effectivity_start;
        effectivity_end = bucket.effectivity_end;
      } else {
        switch (bucket.platform) {
          case 'mediawatch':
            effectivity_start = subscriptionData?.plan_details_mediawatch.billing_start;
            effectivity_end = subscriptionData?.plan_details_mediawatch.billing_end;
            break;
          case 'adwatch':
            effectivity_start = subscriptionData?.plan_details_adwatch.billing_start;
            effectivity_end = subscriptionData?.plan_details_adwatch.billing_end;
            break;
          default:
            effectivity_start = subscriptionData?.plan_details_sharedview.billing_start;
            effectivity_end = subscriptionData?.plan_details_sharedview.billing_end;
        }
      }

      return {
        ...bucket,
        effectivity_start,
        effectivity_end,
      };
    });
  }, [bucketsData?.data, subscriptionData]);

  const { data: singleBucketData } = getBuckets({
    buckets_id: [BUCKET_ID],
    page: page,
    pageSize: pageSize,
  });

  const get_bucket_data = useMemo(() => {
    if (!singleBucketData?.data) {
      return [];
    }

    return singleBucketData.data.map((bucket) => {
      let effectivity_start;
      let effectivity_end;

      if (bucket.effectivity_start && bucket.effectivity_end) {
        effectivity_start = bucket.effectivity_start;
        effectivity_end = bucket.effectivity_end;
      } else {
        switch (bucket.platform) {
          case 'mediawatch':
            effectivity_start = subscriptionData?.plan_details_mediawatch.billing_start;
            effectivity_end = subscriptionData?.plan_details_mediawatch.billing_end;
            break;
          case 'adwatch':
            effectivity_start = subscriptionData?.plan_details_adwatch.billing_start;
            effectivity_end = subscriptionData?.plan_details_adwatch.billing_end;
            break;
          default:
            effectivity_start = subscriptionData?.plan_details_sharedview.billing_start;
            effectivity_end = subscriptionData?.plan_details_sharedview.billing_end;
        }
      }

      return {
        ...bucket,
        effectivity_start,
        effectivity_end,
      };
    });
  }, [singleBucketData, subscriptionData]);

  const { data: keyword_data } = getKeywordShow({
    keywords_id: locationState?.keyword_id
      ? [locationState?.keyword_id]
      : [get_bucket_data[0]?.boolean_keywords_id[get_bucket_data[0]?.boolean_keywords_id.length - 1]?._id],
    limit: 100,
  });

  const get_keywords_data = useMemo(() => keyword_data?.data || [], [keyword_data]);

  // useEffect(() => {
  //   const get_boolean_keywords = () => {
  //     return get_bucket_data[0]?.boolean_keywords_id;
  //   };

  //   const execute_bucket_update = (values: any, focusKeywords?: any, focus?: boolean) => {
  //     const focus_keywords: any[] = focusKeywords
  //       ? focusKeywords.focusKeywords.map((focusKeyword) => ({
  //           boolean_keywords_id: focusKeywords.keywordID,
  //           keyword_focus: focusKeyword,
  //         }))
  //       : [];

  //     console.log(get_bucket_data);
  //     console.log(focus_keywords);

  //     // mutateUpdateBucket(
  //     //   {
  //     //     _id: get_bucket_data[0]?._id,
  //     //     bucket_name: bucketName,
  //     //     platform: locationState?.platform,
  //     //     boolean_keywords: values,
  //     //     updated_by: user._id,
  //     //     client_obj_id: locationState?.client_id,
  //     //     focus_keywords_id: focus_keywords,
  //     //     remove_focus_keywords_id: [],
  //     //   },
  //     //   {
  //     //     onSuccess: () => {
  //     //       queryClient.invalidateQueries(['bucket'], {
  //     //         refetchInactive: true,
  //     //       });

  //     //       queryClient.invalidateQueries(['category'], {
  //     //         refetchInactive: true,
  //     //       });

  //     //       queryClient.invalidateQueries(['keywords'], {
  //     //         refetchInactive: true,
  //     //       });

  //     //       setProceed(false);
  //     //       const saving_focus_keywords: boolean = focus ? focus === true && true : false;
  //     //       if (saving_focus_keywords === false) {
  //     //         setProceedSavingKeywords({
  //     //           ...proceedSavingFocusKeywords,
  //     //           ['bucket_name']: bucketName,
  //     //           ['proceed']: true,
  //     //         });
  //     //       }

  //     //       if (!proceedSavingFocusKeywords.focusKeywords.length || (focus && focus === true)) {
  //     //         if (proceedSavingFocusKeywords.save_alert === 'focus') {
  //     //           setProceedSavingKeywords({
  //     //             ['bucket_name']: '',
  //     //             ['proceed']: false,
  //     //             ['focusKeywords']: [],
  //     //             ['save_alert']: '',
  //     //           });
  //     //         }
  //     //       }

  //     //       push(ROUTES.ADMIN.CLIENTS.BUCKETS.LIST, {
  //     //         _id: get_category_data[0]?._id,
  //     //         buckets: get_buckets,
  //     //         category_name: locationState?.category_name,
  //     //         client_id: locationState?.client_id,
  //     //         platform: locationState?.platform,
  //     //       });
  //     //     },
  //     //     onError: (error: any) => {
  //     //       if (error?.response?.data?.server_response) {
  //     //         Alert.error(error.response.data.server_response);
  //     //       } else {
  //     //         Alert.error('Somethings Went Wrong');
  //     //       }
  //     //     },
  //     //   },
  //     // );
  //   };
  //   // const save_keywords = () => {
  //   //   execute_bucket_update(get_boolean_keywords());
  //   // };

  //   const save_focus_keywords_data = () => {
  //     execute_bucket_update(
  //       get_boolean_keywords(),
  //       {
  //         keywordID: get_bucket_data[0]?.boolean_keywords_id[get_bucket_data[0]?.boolean_keywords_id.length - 1]?._id,
  //         focusKeywords: proceedSavingFocusKeywords.focusKeywords,
  //       },
  //       true,
  //     );
  //   };

  //   // if (get_bucket_data[0]?.bucket_name === bucketName && proceed === true) {
  //   //   save_keywords();
  //   // }

  //   if (proceedSavingFocusKeywords.proceed === true && proceedSavingFocusKeywords.bucket_name) {
  //     if (proceedSavingFocusKeywords.focusKeywords.length) {
  //       save_focus_keywords_data();
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [proceed, proceedSavingFocusKeywords]);

  const fetched_data_initial_values = useMemo(() => {
    const fetchedBucketData = get_bucket_data[0];
    const fetchedKeywordData = get_keywords_data[0];
    const keywordData = fetchedKeywordData?.keywords;

    let keywordsEdit = '';
    let additionalKeywordsEdit = '';
    let additionalKeywords1Edit = '';
    let additionalKeywords2Edit = '';
    let additionalKeywords3Edit = '';
    let excludedKeywordsEdit = '';

    let getKeywords: string[] = [];
    let getAdditionalKeywords: string[] = [];
    let getAdditionalKeywords1: string[] = [];
    let getAdditionalKeywords2: string[] = [];
    let getAdditionalKeywords3: string[] = [];
    let getExcludedKeywords: string[] = [];

    const keywordsSplit = keywordData ? keywordData.split(') ') : [];

    if (keywordsSplit.length > 0) {
      keywordsEdit = keywordsSplit[0];
      getKeywords = keywordsEdit.replace(/([""()])/g, '').split('|');
    }

    if (keywordsSplit.length > 1 && !keywordsSplit[1].includes('-(')) {
      additionalKeywordsEdit = keywordsSplit[1];
      getAdditionalKeywords = additionalKeywordsEdit.replace(/([""()])/g, '').split('|');
    }

    if (keywordsSplit.length > 2 && !keywordsSplit[2].includes('-(')) {
      additionalKeywords1Edit = keywordsSplit[2];
      getAdditionalKeywords1 = additionalKeywords1Edit.replace(/([""()])/g, '').split('|');
    }

    if (keywordsSplit.length > 3 && !keywordsSplit[3].includes('-(')) {
      additionalKeywords2Edit = keywordsSplit[3];
      getAdditionalKeywords2 = additionalKeywords2Edit.replace(/([""()])/g, '').split('|');
    }

    if (keywordsSplit.length > 4 && !keywordsSplit[4].includes('-(')) {
      additionalKeywords3Edit = keywordsSplit[4];
      getAdditionalKeywords3 = additionalKeywords3Edit.replace(/([""()])/g, '').split('|');
    }

    if (keywordData?.includes('-(')) {
      excludedKeywordsEdit = keywordsSplit.filter((keyword) => keyword.includes('-('));
      getExcludedKeywords = excludedKeywordsEdit[0].replace(/([""()-])/g, '').split('|');
    }

    return {
      _id: fetchedBucketData?._id,
      bucket_name: fetchedBucketData?.bucket_name,
      platform: fetchedBucketData?.platform,
      date_created: fetchedBucketData?.date_created,
      effectivity_start: fetchedBucketData?.effectivity_start,
      effectivity_end: fetchedBucketData?.effectivity_end,
      follow_subscription_date: fetchedBucketData?.follow_subscription_date,
      keywords: getKeywords,
      additionalKeywords: getAdditionalKeywords,
      excludedKeywords: getExcludedKeywords,
      modified_keywords: '',
      additionalKeywordMain: getAdditionalKeywords1,
      additionalKeywordAdditional: getAdditionalKeywords2,
      additionalExcluded: getAdditionalKeywords3,
      // focus_keywords: get_bucket_data[0]?.keywords_focuses[0]?.keyword_focus,
      focus_keywords: [
        ...new Set(
          fetchedBucketData?.keywords_focuses
            .filter(({ boolean_keywords_id }) => boolean_keywords_id === locationState?.keyword_id)
            .map(({ keyword_focus }) => keyword_focus)
            .flat(1),
        ),
      ],
      modified_focus_keywords: [],
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleBucketData, keyword_data]);

  const handleSubmitEdit = (values: any) => {
    const buckets: any[] = [];
    const other_bucket = get_category_data[0]?.buckets.filter(
      (val) => val.bucket_name !== get_bucket_data[0]?.bucket_name,
    );

    if (other_bucket.length) {
      buckets.push(...other_bucket.map((bucket) => ({ _id: bucket._id })));
    }

    buckets.push({
      _id: values._id,
      platform: locationState?.platform,
      bucket_name: values.bucket_name,
      updated_by: user._id,
      boolean_keywords: [...get_bucket_data[0]?.boolean_keywords_id],
      client_obj_id: locationState?.client_id,
      effectivity_start: values.follow_subscription_date ? null : values.effectivity_start,
      effectivity_end: values.follow_subscription_date ? null : values.effectivity_end,
      follow_subscription_date: values.follow_subscription_date,
    });

    updateCategoryMutation.mutate(
      {
        client_obj_id: locationState?.client_id,
        _id: get_category_data[0]?._id,
        category_name: locationState?.category_name,
        platform: locationState?.platform,
        buckets: buckets,
        order_num: get_category_data[0]?.order_num,
        updated_by: user._id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['category'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['bucket'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['keywords'], {
            refetchInactive: true,
          });

          Alert.success(`Bucket Successfully Updated.`);
          setLoading(false);

          push(ROUTES.ADMIN.CLIENTS.BUCKETS.LIST, {
            _id: get_category_data[0]?._id,
            buckets: get_buckets,
            category_name: locationState?.category_name,
            client_id: locationState?.client_id,
            platform: locationState?.platform,
          });
        },
        onError: (error: any) => {
          if (error?.response?.data?.server_response) {
            Alert.error(error.response.data.server_response);
          } else {
            Alert.error('Something Went Wrong');
          }
        },
      },
    );
  };

  const handleSubmitKeyword = (form) => {
    let keywordsUpdateID: any = [];

    keywordsUpdateID = singleBucketData?.data[0].boolean_keywords_id;

    if (locationState?.keyword_id) {
      keywordsUpdateID.push({
        _id: locationState?.keyword_id,
        keywords: form.modified_keywords,
        keyword_label: get_keywords_data[0].keywords_label,
        updated_by: user._id,
      });
    } else {
      keywordsUpdateID.push({
        keywords: form.modified_keywords,
        keywords_label: '',
        created_by: user._id,
        access_token: ls.get('user_admin')?.token || undefined,
      });
    }

    mutateUpdateBucket(
      {
        _id: BUCKET_ID,
        bucket_name: form.bucket_name,
        platform: 'mediawatch',
        boolean_keywords: keywordsUpdateID,
        updated_by: user._id,
        client_obj_id: locationState?.client_id,
        focus_keywords_id:
          form.modified_focus_keywords?.length > form.focus_keywords?.length && locationState?.keyword_id
            ? form.modified_focus_keywords
                .filter((focus) => !form.focus_keywords.includes(focus))
                .map((focusKeyword) => ({
                  boolean_keywords_id: locationState?.keyword_id,
                  keyword_focus: focusKeyword,
                }))
            : [],
        remove_focus_keywords_id:
          form.modified_focus_keywords?.length < form.focus_keywords?.length && locationState?.keyword_id
            ? get_bucket_data[0]?.keywords_focuses
                .filter(({ boolean_keywords_id }) => boolean_keywords_id === locationState?.keyword_id)
                .filter(({ keyword_focus }) =>
                  form.focus_keywords
                    .filter((focus) => !form.modified_focus_keywords.includes(focus))
                    .includes(keyword_focus),
                )
                .map(({ _id }) => _id)
            : [],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['category'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['bucket'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['keywords'], {
            refetchInactive: true,
          });
          Alert.success(`Keyword Successfully ${locationState?.keyword_id ? 'Updated' : 'Created'}.`);
          setLoading(false);

          // if (!locationState?.keyword_id) {
          //   setBucketName(form.bucket_name);
          //   setUpdateKeywords(form.modified_keywords);
          //   if (form.modified_focus_keywords.length > 0) {
          //     setProceedSavingKeywords({
          //       ...proceedSavingFocusKeywords,
          //       ['focusKeywords']: form.modified_focus_keywords,
          //       ['bucket_name']: form.bucket_name,
          //       ['save_alert']: 'focus',
          //       ['proceed']: true,
          //     });
          //   } else {
          //     setProceedSavingKeywords({ ...proceedSavingFocusKeywords, ['save_alert']: 'no focus' });
          //   }
          //   setProceed(true);
          //   // console.log('set');
          // } else {
          push(ROUTES.ADMIN.CLIENTS.BUCKETS.LIST, {
            _id: locationState?._id,
            buckets: get_buckets,
            category_name: locationState?.category_name,
            client_id: locationState?.client_id,
            platform: locationState?.platform,
          });
          // }
        },
        onError: (error: any) => {
          if (error?.response?.data?.server_response) {
            Alert.error(error.response.data.server_response);
          } else {
            Alert.error('Something Went Wrong');
          }
        },
      },
    );
  };

  const updateBucketForm = useFormik({
    enableReinitialize: true,
    initialValues: locationState?.addKeyword
      ? locationState?.keyword_id
        ? fetched_data_initial_values
        : {
            _id: get_bucket_data[0]?._id,
            bucket_name: get_bucket_data[0]?.bucket_name,
            platform: get_bucket_data[0]?.platform,
            date_created: get_bucket_data[0]?.date_created,
            effectivity_start: get_bucket_data[0]?.effectivity_start,
            effectivity_end: get_bucket_data[0]?.effectivity_end,
            follow_subscription_date: get_bucket_data[0]?.follow_subscription_date,
            keywords: [],
            additionalKeywords: [],
            excludedKeywords: [],
            modified_keywords: '',
            additionalKeywordMain: [],
            additionalKeywordAdditional: [],
            additionalExcluded: [],
            focus_keywords: [],
            modified_focus_keywords: [],
          }
      : fetched_data_initial_values,
    onSubmit: locationState?.addKeyword ? handleSubmitKeyword : handleSubmitEdit,
  });

  const redirect_cancel = {
    _id: get_category_data[0]?._id,
    buckets: get_buckets,
    category_name: locationState?.category_name,
    client_id: locationState?.client_id,
    platform: locationState?.platform,
  };

  // ADD BUCKET NAME ON EDIT IN BREADCRUMB
  useEffect(() => {
    setAdditionalValue(get_bucket_data[0]?.bucket_name);

    return () => {
      setAdditionalValue(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_bucket_data]);

  // ON PAGE LOAD SET TO FALSE FIRST TO STOP LOADING
  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinnerWrapper spinning={loading} position="top">
      <EditView
        updateForm={updateBucketForm}
        setLoading={setLoading}
        clientID={locationState?.client_id}
        categoryName={locationState?.category_name}
        addKeyword={locationState?.addKeyword}
        type={locationState?.type}
        cancel={redirect_cancel}
        keyword_id={locationState?.keyword_id}
        subscriptionData={subscriptionData}
      />
    </SpinnerWrapper>
  );
}

export default Container;
